<template>
  <div id="app antialiased">
    <banner phone-number="332-334-8664" :on-click="scheduleAppt" />
    <section-header
      schedule-url="https://www.typeform.com/"
      :on-click="scheduleAppt"
    />

    <main>
      <section-hero
        schedule-url="https://www.typeform.com/"
        video-webm="/videos/pain-relief.webm"
        video-mp4="/videos/pain-relief.mp4"
        poster="/images/pain-still.webp"
        header="Powerful pain relief made easy"
        lead="Get pain treatment from our team of world class, award-winning doctors with locations across Manhattan, Long Island, Westchester and the Bronx."
        :on-click="scheduleAppt"
      />
      <section-insurance />
      <delay-hydration>
        <section-doctor />
        <section-how-it-works />
        <section-testimonials :testimonials="testimonials" />
        <section-treatments :treatments="treatments" />
        <section-clinics />
        <section-faq />
        <section-video />
        <section-benchmarks />
        <section-disclosures />
      </delay-hydration>
    </main>
  </div>
</template>

<script setup>
import { withQuery } from "ufo";
import EpiduralSteroidsIcon from "~/assets/images/icons/epidural-steroids.svg";
import SelectiveNerveBlockIcon from "~/assets/images/icons/selective-nerve-block.svg";
import SpinalCordStimulatorsIcon from "~/assets/images/icons/spinal-cord-stimulators.svg";
import TriggerPointInjectionsIcon from "~/assets/images/icons/trigger-point-injections.svg";
const { gtag } = useGtag();

const route = useRoute();
const url = withQuery("https://un6a7rqgzdf.typeform.com/to/BjqppZM5", {
  ...route.query,
});
useSeoMeta({
  title: "Manhattan Restorative Health Sciences | Pain",
  ogTitle: "Manhattan Restorative Health Sciences | Pain",
  description:
    "MRHS, led by Dr. Tim Canty, offers innovative 'body and mind' treatments and groundbreaking medical research in Manhattan.",
  ogDescription:
    "MRHS, led by Dr. Tim Canty, offers innovative 'body and mind' treatments and groundbreaking medical research in Manhattan.",
});

const scheduleAppt = () => {
  gtag("event", "schedule_appointment", {
    event_category: "lead",
  });
  gtag("event", "conversion", {
    send_to: "AW-16450513753/LjlOCPiazo8ZENnWm6Q9",
  });
  navigateTo(url, {
    external: true,
    open: {
      target: "_blank",
    },
  });
};

const testimonials = [
  {
    name: "Julio M, age 52",
    job: "HVAC Technician",
    quote:
      "Ever since I started MRHS, my evenings after long workdays have become much more comfortable. Dr. Canty understood my needs and provided treatments that offer lasting relief. Thanks to their expertise, I can now enjoy my leisure time without discomfort.",
    image: "/images/julio.png",
  },
  {
    name: "Manuel G, age 48",
    job: "Carpenter",
    quote:
      "MRHS has changed my life. The physical demands of carpentry used to leave me with unbearable aches and pains. Since I began treatments with Dr. Canty, my after-work hours have become a time of relaxation and comfort. Dr. Canty's understanding of my unique needs helped him create a targeted treatment program. Thanks to his expert care, I can now enjoy my evenings without the constant discomfort that used to overshadow my leisure time.",
    image: "/images/manuel.png",
  },
];

const treatments = [
  {
    name: "Epidural Steroids",
    icon: EpiduralSteroidsIcon,
  },
  {
    name: "Spinal Cord Stimulators",
    icon: SpinalCordStimulatorsIcon,
  },
  {
    name: "Selective Nerve Block",
    icon: SelectiveNerveBlockIcon,
  },

  {
    name: "Trigger Point Injections",
    icon: TriggerPointInjectionsIcon,
  },
];
</script>
