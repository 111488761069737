<template>
  <section id="treatments">
    <section-container class="mt-10 mb-10 pb-10">
      <div class="text-center mb-10">
        <text-h2>{{ heading }}</text-h2>
      </div>
      <div class="">
        <ul role="list" class="mt-3 grid grid-cols-2 sm:gap-6 lg:grid-cols-4">
          <li
            v-for="treatment in treatments"
            :key="treatment.name"
            class="col-span-1 mx-auto"
          >
            <div class="flex flex-1 r">
              <div class="flex-1 text-sm">
                <component :is="treatment.icon" class="w-28 lg:w-32" />
                <div class="text-lg font-semibold w-32 text-center">
                  {{ treatment.name }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section-container>
  </section>
</template>

<script setup>
defineProps({
  treatments: {
    type: Array,
    default: () => [],
  },
});

const heading = "Book a Consultation to Discuss Treatment Options Today";
</script>
