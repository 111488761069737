<template>
  <section id="benchmarks">
    <section-container class="mt-8 mb-10 pb-10">
      <div class="flex justify-center">
        <ul
          role="list"
          class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2"
        >
          <li v-for="stat in stats" :key="stat.title" class="col-span-1 flex">
            <div class="flex flex-1 items-center justify-between truncate r">
              <div class="flex-1 truncate px-4 py-2">
                <p
                  class="text-[4em] lg:text-[6em] text-center font-semibold text-accent"
                >
                  {{ stat.value }}
                </p>
                <p class="text-center uppercase md:text-xl">
                  {{ stat.title }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section-container>
  </section>
</template>

<script setup>
const stats = [
  {
    title: "Innovative Procedures Performed",
    value: "40,000+",
  },
  {
    title: "Advanced Restorative Therapies",
    value: "59",
  },
  {
    title: "State-of-the-Art Facilities",
    value: "12",
  },
  {
    title: "Stars On Google Reviews",
    value: "4.9",
  },
];
</script>
