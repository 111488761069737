<template>
  <section>
    <text-h2 class="text-center">Learn more about MRHS</text-h2>
    <section-container class="mt-12 flex justify-center">
      <ui-video-player />
    </section-container>
  </section>
</template>

<script setup></script>
