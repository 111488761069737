<template>
  <section id="how-it-works">
    <section-container>
      <text-h2 class="text-center text-2xl mt-10">How it works</text-h2>
      <div class="flex justify-center">
        <div class="flex flex-row gap-40 font-light text-lg">
          <div
            class="grid gap-10 md:gap-8 px-5 md:px-10 mt-10 mb-20 md:grid-cols-2"
          >
            <div class="leading-8">
              <h3 class="text-2xl text-accent mb-4 flex gap-4 items-center">
                <ui-numeric-circle>1</ui-numeric-circle>
                <span>Consultation</span>
              </h3>
              You'll speak with a care provider who will discuss your concerns
              and various treatment options while creating a customized wellness
              plan for your mental health journey.
            </div>
            <div>
              <h3 class="text-2xl text-accent mb-4 flex gap-4 items-center">
                <ui-numeric-circle>2</ui-numeric-circle>
                <span>Initial treatment</span>
              </h3>
              Our team will schedule you for your initial treatment at one of
              our offices - conveniently located in Manhattan, Long Island,
              Queens and the Bronx.
            </div>
            <div>
              <h3 class="text-2xl text-accent mb-4 flex gap-4 items-center">
                <ui-numeric-circle>3</ui-numeric-circle>
                <span>Integration</span>
              </h3>
              After your initial treatment, a dedicated care team member will
              reach out about potential follow-up and to address any concerns.
            </div>
            <div>
              <h3 class="text-2xl text-accent mb-4 flex gap-4 items-center">
                <ui-numeric-circle>4</ui-numeric-circle>
                <span>Beyond</span>
              </h3>
              We're dedicated to being your long-term wellness partners for your
              mental health journey.
            </div>
          </div>
        </div>
      </div>
    </section-container>
  </section>
</template>

<script setup></script>
