<template>
  <div class="pt-10">
    <div class="mx-auto px-6 lg:px-8 pt-16 pb-10">
      <div class="mx-auto max-w-6xl">
        <text-h2 class="text-center">Frequently asked questions</text-h2>
        <dl class="mt-10 space-y-5 p-3">
          <Disclosure
            v-for="(faq, index) in faqs"
            :key="index"
            v-slot="{ open }"
            as="div"
            class="py-4 bg-gray-100"
          >
            <dt class="">
              <DisclosureButton
                class="flex w-full items-start justify-between text-left text-gray-900"
              >
                <span class="text-base font-semibold leading-7 px-3">{{
                  faq.question
                }}</span>
                <span class="ml-6 flex h-7 items-center pr-5">
                  <ChevronDownIcon
                    v-if="!open"
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                  <ChevronRightIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-gray-600 px-3">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

const faqs = [
  {
    question: "Am I able to reschedule my appointment if my plans change?",
    answer:
      "In order to be respectful of the medical considerations of our patients and the need for sometimes urgent appointments, please call our office promptly if you are unable to attend an appointment. This time will be reallocated to someone who is in urgent need of treatment. If it is necessary to cancel your scheduled office appointment, we require that you call 24 business hours in advance. For procedure appointments, we require you to call 72 business hours in advance. Appointments are in high demand, and your early cancellation will give another person the opportunity to have access to timely medical care.",
  },
  {
    question: "How long will my appointment take?",
    answer:
      "The length of your appointment will depend on several factors. Please arrive 15 minutes prior to your appointment time.",
  },
  {
    question: "How do I get my patient paperwork?",
    answer:
      "Prior to your appointment, you will receive an email/text message link from our check-in partner, Phreesia, to complete your new patient paperwork. If you do not receive this link, you may call our office to request one or simply arrive 45 minutes before your appointment to complete the questionnaire in our office. Please note, you may be required to complete additional paperwork in the office prior to your first appointment.",
  },
];
</script>
