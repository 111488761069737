<template>
  <section id="doctors">
    <div class="bg-secondary pt-16 pb-10">
      <text-h2 class="text-white text-center">Meet Doctor Tim Canty</text-h2>
      <section-container>
        <div class="flex justify-center items-center flex-col">
          <img
            class="object-cover h-[400px] w-[655px] rounded-md mt-10"
            src="/images/timothy-canty-md@2x.jpg"
            width="655"
            height="400"
          />
        </div>
        <div class="flex justify-center pt-4">
          <ul
            role="list"
            class="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-7"
          >
            <li
              v-for="credential in credentials"
              :key="credential.name"
              class="col-span-1 flex"
            >
              <div class="flex flex-1 items-center justify-between truncate r">
                <div class="flex-1 truncate px-4 py-2 text-sm">
                  <component :is="credential.logo" class="w-28 md:w-20" />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="text-white mt-10 flex justify-center pb-10">
          <div
            class="grid-flow-row grid md:grid-cols-2 gap-8 xl:mt-0 max-w-4xl"
          >
            <div>
              <text-h4>Fellowship</text-h4>
              <muted>Harvard Medical School | Beth Israel Deaconess</muted>
              <p class="leading-8">
                A comprehensive multidisciplinary approach to pain management,
                featuring extensive training in a full range of interventional
                techniques.
              </p>
            </div>
            <div>
              <text-h4>Residency</text-h4>
              <muted>
                Harvard Medical School | Massachusetts General Hospital
              </muted>
              <p>Anesthesiology and Critical Care</p>
            </div>
            <div>
              <text-h4>Medical Degree</text-h4>
              <muted>University of Texas Health Science Center</muted>
            </div>
            <div>
              <text-h4>Clinical Interests</text-h4>
              <div>
                <ul class="list-disc ml-4 space-y-2">
                  <li>
                    Advanced regenerative and restorative techniques,
                    encompassing FDA-approved treatments that have shown
                    efficacy in clinical trials
                  </li>
                  <li>Cutting-edge non-surgical techniques</li>
                  <li>Remediation of pain post unsuccessful surgery</li>
                  <li>
                    Behavioral Intervention Strategies for KVR, Depression,
                    Anxiety, and ME/CFS, & POTS
                  </li>
                  <li>Vertebral compression fractures</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section-container>
    </div>
  </section>
</template>

<script setup>
import ama from "~/assets/images/credentials/ama.svg";
import Brown from "~/assets/images/credentials/brown.svg";
import Cornell from "~/assets/images/credentials/cornell.svg";
import Harvard from "~/assets/images/credentials/harvard.svg";
import MountSinai from "~/assets/images/credentials/mount-sinai.svg";
import Northwell from "~/assets/images/credentials/northwell.svg";
import nyu from "~/assets/images/credentials/nyu.svg";

const credentials = [
  {
    name: "Harvard University",
    logo: Harvard,
  },

  {
    name: "Brown University",
    logo: Brown,
  },
  {
    name: "Cornell University",
    logo: Cornell,
  },
  {
    name: "American Medical Association",
    logo: ama,
  },
  {
    name: "Mount Sinai",
    logo: MountSinai,
  },
  {
    name: "Northwell",
    logo: Northwell,
  },
  {
    name: "NYU",
    logo: nyu,
  },
];
</script>
