<template>
  <div class="pt-10">
    <div class="mx-auto px-6 lg:px-8 pt-16 pb-10">
      <div class="mx-auto max-w-6xl text-gray-400 text-xs">
        <h3 class="mt-2 font-semibold ">Adult Indications for Use</h3>
        <p class="mt-2">
          The NeuroStar Advanced Therapy System is indicated for the treatment of depressive 
          episodes and for decreasing anxiety symptoms for those who may exhibit comorbid 
          anxiety symptoms in adult patients suffering from Major Depressive Disorder (MDD) 
          and who failed to achieve satisfactory improvement from previous antidepressant 
          medication treatment in the current episode.
        </p>
        <p class="mt-2">
          The NeuroStar Advanced Therapy System is intended to be used as an adjunct 
          for the treatment of adult patients suffering from Obsessive-Compulsive Disorder (OCD).
        </p>
        <h3 class="mt-2 font-semibold">Adolescent Indications for Use</h3>
        <p class="mt-2">
          NeuroStar Advanced Therapy is indicated as an adjunct for the treatment of Major 
          Depressive Disorder (MDD) in adolescent patients (15-21).
        </p>
        <p class="mt-2">
          NeuroStar Advanced Therapy is only available by prescription. A doctor can help 
          decide if NeuroStar Advanced Therapy is right for you. Patients' results may vary.
      </p>
        <h3 class="mt-2 font-semibold">Important Safety Information</h3>
        <p class="mt-2">
          The most common side effect is pain or discomfort at or near the treatment site. These 
          events are transient; they occur during the TMS treatment course and do not occur for most 
          patients after the first week of treatment. There is a rare risk of seizure associated 
          with the use of TMS therapy (per patient).
        </p>
        <p class="mt-2">
          NeuroStar Advanced Therapy should not be used with patients who have non-removable 
          conductive metal in or near the head. NeuroStar Advanced Therapy has not been studied 
          in patients who have not received prior antidepressant treatment.
        </p>
      </div>
    </div>
  </div>
</template>

