<template>
  <section id="insurance">
    <section-container class="-mt-10 mb-10">
      <div class="text-center">
        <text-h2 class="text-3xl">We accept insurance</text-h2>
      </div>
      <div class="flex justify-center">
        <ul
          role="list"
          class="mt-3 grid grid-cols-2 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3"
        >
          <li
            v-for="insurer in insurers"
            :key="insurer.name"
            class="col-span-1 flex"
          >
            <div class="flex flex-1 items-center justify-between truncate r">
              <div class="flex-1 truncate px-4 py-2 text-sm">
                <component :is="insurer.logo" class="w-28 md:w-40" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section-container>
  </section>
</template>

<script setup>
import Aetna from "~/assets/images/providers/aetna.svg";
import BlueCross from "~/assets/images/providers/blue-cross.svg";
import Cigna from "~/assets/images/providers/cigna.svg";
import EmblemHealth from "~/assets/images/providers/emblem-health.svg";
import EmpireBlueCross from "~/assets/images/providers/empire-blue-cross.svg";
import HealthFirst from "~/assets/images/providers/health-first.svg";
import Medicare from "~/assets/images/providers/medicare.svg";
import Oxford from "~/assets/images/providers/oxford.svg";
import Tricare from "~/assets/images/providers/tricare.svg";
import UnitedHealthCare from "~/assets/images/providers/united.svg";

const insurers = [
  {
    name: "Medicare",
    logo: Medicare,
  },

  {
    name: "Empire Blue Cross",
    logo: EmpireBlueCross,
  },
  {
    name: "Aetna",
    logo: Aetna,
  },
  {
    name: "United Health Care",
    logo: UnitedHealthCare,
  },
  {
    name: "Oxford",
    logo: Oxford,
  },
  {
    name: "Tricare",
    logo: Tricare,
  },
  {
    name: "Cigna",
    logo: Cigna,
  },
  {
    name: "Blue Cross",
    logo: BlueCross,
  },
  {
    name: "Health First",
    logo: HealthFirst,
  },
  {
    name: "Emblem Health",
    logo: EmblemHealth,
  },
];
</script>
