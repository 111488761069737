<template>
  <div class="pt-10">
    <div class="mx-auto px-6 lg:px-8 pt-16 pb-10">
      <div class="mx-auto max-w-6xl">
        <text-h2 class="text-center">Locations</text-h2>
        <text-lead class="text-black mx-auto text-left"
          >We're proud to offer the most convenient restorative care in Greater
          New York City, with 12 state-of-the-art clinics throughout the city
          and Nassau County.</text-lead
        >
        <h5 class="text-xl uppercase text-left mb-4">Main Clinics</h5>
        <div class="flex flex-col">
          <ul
            role="list"
            class="grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-4"
          >
            <li
              v-for="(clinic, key) in mainClinics"
              :key="key"
              class="md:text-md col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow mb-10"
            >
              <div
                class="flex w-full items-center justify-between space-x-6 p-6"
              >
                <div class="flex-1 truncate">
                  <div class="">
                    <img
                      class="hidden md:block object-cover w-[320px] rounded-xl p-2 grayscale"
                      :src="clinic.image"
                      width="1900"
                      height="1280"
                    />
                    <img
                      class="md:hidden object-cover w-[320px] rounded-xl p-2"
                      :src="clinic.image"
                      width="1900"
                      height="1280"
                    />
                  </div>
                  <div class="flex items-center space-x-3 px-2">
                    <h3 class="truncate font-medium text-gray-900">
                      {{ clinic.name }}
                    </h3>
                  </div>

                  <ul
                    class="mt-1 truncate text-gray-500 px-2 text-sm md:text-md"
                  >
                    <li>{{ clinic.street }}</li>
                    <li>
                      {{ clinic.city }}, {{ clinic.state }} {{ clinic.zip }}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <dl class="mt-10 space-y-5">
          <Disclosure v-slot="{ open }" as="div" class="py-4 bg-gray-100">
            <dt class="">
              <DisclosureButton
                class="flex w-full items-start justify-between text-left text-gray-900"
              >
                <span class="text-base font-semibold leading-7 px-3"
                  >Partner Clinics</span
                >
                <span class="ml-6 flex h-7 items-center pr-5">
                  <ChevronDownIcon
                    v-if="!open"
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                  <ChevronRightIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <div class="px-3">
                <clinic-location
                  :clinics="partnersClinics['Manhattan & Bronx']"
                  name="Manhattan & Bronx"
                />

                <clinic-location
                  :clinics="partnersClinics['Long Island']"
                  name="Long Island"
                />

                <clinic-location
                  :clinics="partnersClinics['Westchester County']"
                  name="Westchester County"
                />
              </div>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";

const mainClinics = [
  {
    name: "MRHS",
    street: "295 Madison Ave #407",
    city: "New York",
    State: "NY",
    zip: "10017",
    image: "/images/295-madison-ave.jpg",
  },
  {
    name: "Lexington Medical Associates",
    street: "139 E 57th St 8th Floor",
    city: "New York",
    State: "NY",
    zip: "10022",
    image: "/images/139-e-57th-st.jpg",
  },
  {
    name: "Blue Health LES",
    street: "103 Norfolk St",
    city: "New York",
    State: "NY",
    zip: "10002",
    image: "/images/103-norfolk-st.jpg",
  },
  {
    name: "MRHS",
    street: "2001 Marcus Ave #W95",
    city: "New Hyde Park",
    State: "NY",
    zip: "11042",
    image: "/images/2001-marcus-ave.jpg",
  },
];

const partnersClinics = {
  "Manhattan & Bronx": [
    {
      name: "Wagner Building",
      street: "2488 Grand Concourse #310",
      city: "Bronx",
      state: "NY",
      zip: "10458",
    },
    {
      name: "Optimal Rehabilitation OT & PT PLLC",
      street: "721 Melrose Ave",
      city: "Bronx",
      state: "NY",
      zip: "10455",
    },
    {
      name: "Throgs Neck Multi Care, P.C.",
      street: "3058 E Tremont Ave",
      city: "Bronx",
      state: "NY",
      zip: "10461",
    },
    {
      name: "Midland Parkway Center",
      street: "87-01 Midland Parkway",
      city: "Jamaica",
      state: "NY",
      zip: "11432",
    },
    {
      name: "Starrett Podiatry",
      street: "1990A Lexington Ave",
      city: "New York",
      state: "NY",
      zip: "10035",
    },
    {
      name: "Empire State Ambulatory Surgery Center",
      street: "3170 Webster Ave Ste. A",
      city: "Bronx",
      state: "NY",
      zip: "10467",
    },
  ],
  "Long Island": [
    {
      name: "Ainsworth ASC",
      street: "1103 Stewart Ave",
      city: "Garden City",
      state: "NY",
      zip: "11530",
    },
    {
      name: "Dr. Jaghab Chiro",
      street: "300 Hempstead Turnpike",
      city: "West Hempstead",
      state: "NY",
      zip: "11552",
    },
    {
      name: "Fit For Life Chiropractic",
      street: "320 Post Ave #100",
      city: "Westbury",
      state: "NY",
      zip: "11590",
    },
  ],
  "Westchester County": [
    {
      name: "Brain and Spine Surgeons of New York",
      street: "4 Westchester Park Dr 4th Floor",
      city: "White Plains",
      state: "NY",
      zip: "10604",
    },
  ],
};
</script>

<style lang="scss" scoped>
.grayscale {
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
}
</style>
