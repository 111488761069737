<template>
  <div class="video-player aspect-video max-w-5xl">
    <video
      id="video-player"
      rel="video"
      class="video-player cursor-pointer"
      controls
    />
  </div>
</template>

<script setup>
import Plyr from "plyr";
onMounted(() => {
  const player = new Plyr("#video-player", {
    controls: [
      "play",
      "progress",
      "current-time",
      "mute",
      "volume",
      "fullscreen",
    ],
  });
  player.source = {
    type: "video",
    title: "About Us",
    poster: "/images/Poster.jpg",
    sources: [
      {
        src: "/videos/about-us.webm",
        type: "video/webm",
      },
      {
        src: "/videos/about-us.mp4",
        type: "video/mp4",
      },
    ],
  };
});
</script>

<style lang="scss">
.video-player {
  width: 100%;
  height: 100%;
}

:root {
  --plyr-font-smoothing: true;
}
.video__loader {
  background: var(--grey-300);
}
::v-deep(.plyr) {
  width: 100%;
  height: 100%;
}
</style>
